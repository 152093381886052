import React from 'react';
import { connect } from 'react-redux';
import FeaturedCard from '../FeaturedCard/FeaturedCard';
import  './featuredSection.css';





const FeaturedSection = props =>

 (
    <section className="featured section-ptb">
        <h1 className="section-title text-center">Featured Products</h1>
        <div className="featured-content mycontainer">
            {
                props.data.map((data, indx) => <FeaturedCard key={indx} cardData={data} />)
            }
        </div>
   </section >

);

export default connect()(FeaturedSection);