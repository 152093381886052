import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ProductMenu from '../ProductMenu/ProductMenu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/ProductDropdown';

import './NavMenu.css';
import logo from '../../Images/naimex.png';


 class NavMenu extends Component {
     constructor(props) {
         super(props);
         this.toggle = this.toggle.bind(this);
         this.onSubmenuClick = this.onSubmenuClick.bind(this);
         this.state = {
             isOpen: false,
             dropdownData: []
         };

      
     }

     componentDidMount() {
         this.ensureDataFetched();
     }


    async ensureDataFetched() {
         const url = "api/getproductdropdownlinks";
         const response = await fetch(url);
         const dropdowns = await response.json();
        this.setState({ dropdownData: dropdowns});
     }


     onSubmenuClick() {
         this.setState({ isOpen: !this.state.isOpen });
     }


    toggle() {
    this.setState({ isOpen: !this.state.isOpen});
    }


  render () {
    return (

        <header className="menu-header">
            <div className="header-top">
              <span className="header-top--logo">
                    <Link to="/"> <img src={logo} alt="logo" /></Link>
              </span>
                <span className="header-top-contacts"> <span><a href="tel:+905533774404">  <i className="fa fa-phone"></i> +905533774404 </a></span> <span><a href="mailto:export@naimextr.com"><i className="fa fa-envelope"></i> export@naimextr.com </a></span>
                    {/*<span className="seach-box">*/}
                    {/*    <input type="text" className="search-input" />*/}
                    {/*    <span className="search-icon"> <i className="fa fa-search"></i></span>*/}
                    {/*</span>*/}
                </span>
                <svg onClick={() => { this.toggle() }} className="menu-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2 6h20v3H2zm0 5h20v3H2zm0 5h20v3H2z" /></svg>

            </div>
            <nav id="mainMenu" className={  this.state.isOpen ? 'show' : ''}>
                <ul>
                    <li><Link onClick={() => { this.toggle() }} className="main-link--item" to="/">Home</Link></li>
                    <ProductMenu products={this.state.dropdownData} onSubmenu={this.onSubmenuClick}  />
                    <li><Link onClick={() => { this.toggle() }} className="main-link--item" to="/corporate">Corporation</Link></li>
                    <li><Link onClick={() => { this.toggle() }} className="main-link--item" to="/gallery">Gallery</Link></li>
                    <li><Link onClick={() => { this.toggle() }} className="main-link--item" to="/contact">Contact</Link></li>
                    </ul>
                </nav>
             </header>

    );
  }
}

export default connect(
    state => state.dropdowns,
    dispatch => bindActionCreators(actionCreators, dispatch),
)(NavMenu);
