import React from 'react';
import { connect } from 'react-redux';
import './VerticalNavMenu.css';
import { NavLink } from 'react-router-dom';
const VerticalNavMenu = props => {

    return (
        <div className="vertical-nav">
            {
                props.data.map((product, idx) => {
                    return (<div key={product.id} className="vertical-list"> <h5><NavLink activeClassName={`${props.type == "category" ? "categ-active" : ""}`} to={`/productcategory/${product.friendlyUrl}`}> {product.categoryName}  </NavLink></h5> </div>)


                    //if (product.subCategoryMenuItems.length == 0) {
                    //    return (<div key={product.id} className="vertical-list"> <h5><NavLink to={`/productcategory/${product.friendlyUrl}`}> {product.categoryName}  </NavLink></h5> </div>)
                    //} else {
                    //    return (
                    //        <div key={idx} className="vertical-list">
                    //            <h5 key={product.id}><NavLink activeClassName={`${props.type == "category" ? "categ-active" : ""}`} to={`/productcategory/${product.friendlyUrl}`}> {product.categoryName}  </NavLink></h5>
                    //            <div className="vertical-sublist">
                    //                {product.subCategoryMenuItems.map((sub, indx) => {
                    //                    return (<span key={indx} ><NavLink activeClassName={`${props.type == "dealers" ? "categ-active" : ""}`} to={`/productfranchise/${product.id}/${sub.id + '/' + (sub.dealerName).replace(/ /g, '_') }`}>{sub.dealerName}</NavLink ></span>)
                    //                })
                    //                }
                    //            </div>
                    //   </div>)
                    //}
                })
            }
        </div>

    );


}






export default connect()(VerticalNavMenu);
