import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import './simpleslider.css';





const SimpleSlider = (props) => {

    const slider = useRef(null);


    useEffect(() => {
        if (props.index != null) {
            slider.current.slickGoTo(props.index);
        }
    }, [props.index])


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const slideSettings = props.settings ? props.settings : settings;

    return (
        <Slider ref={slider} {...slideSettings}>
            {props.children}
        </Slider>
    );
}


export default SimpleSlider;