import React from 'react';
import { connect } from 'react-redux';
import CategoryCard from '../CategoryCard/CategoryCard';
import SimpleSlider from '../SlickSlider/SlickSlider';

import './categorySection.css';


const CategorySection = props => {

    const slideSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }


    return (

        <section className="product-categories--wrapper  section-ptb">
            <h1 className="section-title text-center">Categories</h1>
            {/*<div className="product-categories mycontainer">*/}
            {/*    {props.data.map((dt, idx) => <CategoryCard key={idx} data={dt} />)}*/}
            {/*</div>*/}

            <div className="mycontainer product-category--slide">
            <SimpleSlider index={0} settings={slideSettings}>
                {props.data.map((dt, idx) => <CategoryCard key={idx} data={dt} />)}
            </SimpleSlider>
            </div>

        </section>
    );


}





export default connect()(CategorySection);
