import React from 'react';
import { connect } from 'react-redux';
import './floatingcontacticons.css';

const FloatingContactIcons = props => (
    <div className="floatingicons-container">
        <ul>
            <li> <a href="https://wa.me/905533774404 " target="_blank" className="ft-whatsapp"> <i className="fa fa-whatsapp" aria-hidden="true"></i></a> </li>
            <li> <a href="tel:+905533774404 " target="_blank" className="ft-phone" ><i className="fa fa-phone" aria-hidden="true"></i></a> </li>
            <li> <a href="https://t.me/naimextr/" target="_blank" className="ft-telegram"> <i className="fa fa-telegram" aria-hidden="true"></i></a> </li>
           
        </ul>
    </div>
);

export default connect()(FloatingContactIcons);
