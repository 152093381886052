import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import BaseButton from '../BaseButton/BaseButton';

import './heroSlider.css'



const HeroSlider = props => {

    return(
        <section className="hero-slider">
            <Slider autoplay={3000}>
                {props.data.map((slide, index) => (
                    <div key={index}
                        style={{ background: `url('${slide.imageUrl}') no-repeat center center`, backgroundSize: 'cover' }}
                    >
                        <div className='slide-content mycontainer'>

                            <div className='slide-content-desc'>
                                <h1>{slide.title}</h1>
                                <p className='slide-description'>{slide.description}</p>
                                <div className='hero-calltoaction'>
                                    <BaseButton title="DETAIL" url={`/productdetail/${slide.friendlyUrl}`} />
                                    <BaseButton title="CONTACT" url="/contact" />
                                </div>
                            </div>
                        </div>
                    </div>
                )
                )}
            </Slider>
        </section>
    )
};

export default connect()(HeroSlider);






