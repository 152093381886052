import * as React from 'react';
import { connect } from 'react-redux';
import FranchiseCard from '../FranchiseCard/FranchiseCard';

import './franchise-section.css';



const Franchise = (props) => (
    <div data-aos="fade-up" className="franchise-container section-ptb">
        <h1 className="section-title text-center">Our Dealers</h1>

        <div className="franchise-content mycontainer">
            {props.data.map((data, idx) => <FranchiseCard key={idx} cardData={data} />)}
        </div>
    </div>
);

export default connect()(Franchise);
