import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
//import FsLightbox from 'fslightbox-react';
import PageBreadCrumb from '../../components/PageBreadCrumb/PageBreadCrumb';
import Loader from '../../components/Loader/Loader';
import SimpleSlider from '../../components/SlickSlider/SlickSlider';
import galleries_bg from '../../Images/galleries_bg.jpg';
import './gallery.css';

const Gallery = () => {

    const [toggler, setToggler] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [isOpenSlickBox, setIsOpenSlickBox] = useState(false);
    const [slickIndex, setSlickIndex] = useState(0);

    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
        document.title = "Gallery | Naimextr Import & Export";
    }, [])


    const onSlickLightBox = (idx) => {
        setIsOpenSlickBox(!isOpenSlickBox);
        setSlickIndex(idx);
    }


    const breadCrumbData = {
        bgImageUrl: galleries_bg,
        pageTitle: "Gallery",
        linkList: [
            { title: "Home", url: "/" },
            { title: "Galleries", url: "/gallery" }
        ]
    };


    const fetchData = async () => {
        const url = "/api/getgalleries";
        const response = await fetch(url);
        const galleries = await response.json();
        setPhotos(galleries);
        setIsLoading(false);
    };

    return (

        <>
         <Loader isLoading={isLoading} />
        <PageBreadCrumb BreadcrumbData={breadCrumbData} />
        <div className="gallery-wrapper">
            <div className="mycontainer">
                <div className="myrow">
                        {photos.map((img, idx) => <img onClick={() => onSlickLightBox(idx)} key={idx} src={img} alt="Demireller Yapi ve Tarim" />)}
                     

                </div>
                    {/*{photos && (<FsLightbox*/}
                    {/*    toggler={toggler}*/}
                    {/*    sources={photos}*/}
                    {/*/>)}*/}

                    <div className={isOpenSlickBox ? 'slider-overlay show' : 'slider-overlay'}>
                        <span className="light-close" onClick={() => setIsOpenSlickBox(!isOpenSlickBox)} ><i className="fa fa-times" aria-hidden="true"></i></span>
                        <div className="slider-light--content">
                            <SimpleSlider index={slickIndex}>
                                {photos.map((img, idx) => <img key={idx} src={img} alt="Demireller Yapi ve Tarim" />)}
                            </SimpleSlider>

                        </div>
                    </div>

            </div>
            </div>
        </>

    );

};

export default connect()(Gallery);