import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import defaultImg from '../../Images/naimex.png';
import './franchise-card.css';


const FranchiseCard = (props) => {
    const { id, name, imgUrl, fkCategory, friendlyUrl } = props.cardData;
    return (
        <div className="franchise-card">
            <img src={`${imgUrl.length > 0 ? imgUrl : defaultImg}`} alt={name} />

            {/*<Link className="franchise-card-link" to={`/productcategory/${friendlyUrl}`}></Link>*/}
            {/*<Link className="franchise-card-link" to={`/productfranchise/${fkCategory}/${id +'/' + name.replace(/ /g, '_')}`}></Link>*/}
        </div>
    );
}

export default connect()(FranchiseCard);