import React, { useEffect, useState} from 'react';
import { connect } from 'react-redux';
import  './footer.css';
import { Link } from 'react-router-dom';
import logo from '../../Images/naimex.png';

const Footer = props => {

    const [data, setData] = useState([]);
    const [about, setAbout] = useState("");

    useEffect(() => {
        fetchCategory();
    },[])

    const fetchCategory = async () => {

        const url = "api/getfootercategory";
        const response = await fetch(url);
        const productData = await response.json();

        if (productData.length > 0) {
            setAbout(productData[0].aboutData[0].descText);
            setData(productData[0].productCategoryData);
        } else {
            console.log(productData);
        }
    };



    return (
        <section className="footer">
            <div className="footer-content mycontainer">
                <div className="footer-content-left">
                    <div className="company-info">
                        <img src={logo} alt="Demireller logo" />
                        <p className="about-company" dangerouslySetInnerHTML={{ __html: about }} />
                        <div className="socialmedia-content">
                            <h5 className="footer-link-title">Social Media</h5>
                            <div className="socialmedia-icons">
                                <ul className="footer-socialmedia-list">
                                    <li className="facebook"><a href="https://www.facebook.com/naimextr/" rel="noreferrer" target='_blank'><i className="fa fa-facebook-official" aria-hidden="true"></i></a></li>
                                    <li className="twitter"><a href="https://twitter.com/naimextr/" rel="noreferrer" target='_blank'><i className="fa fa-twitter-square" aria-hidden="true"></i></a></li>
                                    <li className="instagram"><a href="https://www.instagram.com/naimextr/" rel="noreferrer" target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                   {/* <li className="youtube"><a href="#" target='_blank'><i className="fa fa-linkedin-square" aria-hidden="true"></i></a></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="company-links">
                        <div className="company-links--fast">
                            <h5 className="footer-link-title">Quick Links</h5>
                            <ul className="footer-list">
                                <li><Link to="/"> Home</Link></li>
                                <li><Link to="/corporate/">  Corporation </Link></li>
                                <li><Link to="/gallery"> Gallery </Link></li>
                                <li><Link to="/contact">Contact </Link></li>
                            </ul>
                        </div>
                        <div className="company-links--kategories">
                            <h5 className="footer-link-title">Product Categories</h5>
                            <ul className="footer-list">
                                {data && data.map((dt, idx) =>
                                    <li key={idx}><Link to={`/productcategory/${dt.friendlyUrl}`}> {dt.categoryName} </Link></li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-content-right">

                    {/*<h4 className="footer-link-title1">Contact Info</h4>*/}
                    <h5 className="footer-link-title">Contact Us</h5>

                    <ul className="footer-content-right--list">
                        <li><i className="fa fa-map-marker" aria-hidden="true"></i> <span className="footer-address">Aksaray Mah Inkilap Cad 43/98, Fatih, Istanbul, Turkey, 34093</span></li>
                        <li><i className="fa fa-phone" aria-hidden="true"></i> <span>+905533774404</span></li>
                        {/*<li><i className="fa fa-phone" aria-hidden="true"></i> <span>+9005533774404</span></li>*/}
                        <li> <i className="fa fa-envelope"></i> <span> export@naimextr.com</span></li>
                    </ul>
                    <h5 className="footer-link-title">Working Hours</h5>
                    <p>Everyday 07:30-18:00</p>
                </div>
            </div>
            <footer>
                <p className="mycontainer">Copyright © 2022. All rights reserved.</p>
            </footer>
        </section>
    );


}
 


export default connect()(Footer);